import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withPrefix, graphql } from "gatsby";
import Hidden from "@material-ui/core/Hidden";
import classNames from "classnames";
import Layout from '../components/layout';

const styles = theme => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing.unit * 4,
      paddingRight: theme.spacing.unit * 4,
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing.unit * 22,
      paddingRight: theme.spacing.unit * 22,
      paddingTop: theme.spacing.unit * 5,
      paddingBottom: theme.spacing.unit * 5
    }
  },
  title: {
    ...theme.typography.subheading,
    textTransform: "uppercase",
    color: "black",
    fontSize: "40px",
    fontStyle: "normal",
    fontFamily: "Lato",
    lineHeight: "normal",
    fontWeight: 900,
    fontStretch: "normal",
    letterSpacing: "normal",
  },
  paragraph: {
    paddingTop: "30px",
    ...theme.typography.paragraph,
    lineHeight: "1.8",
    listStyleType: "disc",
    color: "#777777",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Lato",
  },
  space: {
    marginTop: theme.spacing.unit * 4
  },
  right: {
    textAlign: "right"
  },
  center: {
    textAlign: "center"
  },
  gridItem: {
    padding: "20px 10px"
  },
  opacity: {
    opacity: "0.75"
  }
});

const ResultsPageTemplate = ({ data, classes, location }) => {
  const { frontmatter } = data.markdownRemark;
  const desktops = frontmatter.desktop.desktopImages;
  const mobiles = frontmatter.mobile.mobileImages;

  return (
    <Layout location={location}>
      <React.Fragment>
      <Grid container className={classes.container} justify="space-between">
        <Grid item xs={12} md={12}>
          <Typography className={classes.title} gutterBottom>
            {frontmatter.title}
          </Typography>
          <Typography className={classes.paragraph} gutterBottom>
            {frontmatter.description}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} className={classes.space}>
          <Hidden xsDown>
            <Grid container justify="flex-start" alignItems="center">
              {desktops.map((v, k) => (
                <React.Fragment key={k}>
                  {v.left && (
                    <Grid item xs={5} className={classes.gridItem}>
                      <img
                        src={withPrefix(v.left.image)}
                        width={v.left.width}
                      />
                    </Grid>
                  )}
                  {v.center && (
                    <Grid item xs={4} className={classes.gridItem}>
                      <img
                        src={withPrefix(v.center.image)}
                        width={v.center.width}
                      />
                    </Grid>
                  )}
                  {v.right && (
                    <Grid
                      item
                      xs={3}
                      className={classNames(classes.right, classes.gridItem)}
                    >
                      <img
                        className={
                          v.right.image.indexOf("Philips") > 0
                            ? classes.opacity
                            : null
                        }
                        src={withPrefix(v.right.image)}
                        width={v.right.width}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </Hidden>
        </Grid>
        <Grid item xs={12} md={12}>
          <Hidden smUp>
            <Grid container justify="center" alignItems="center">
              {mobiles &&
                mobiles.map((v, k) => (
                  <React.Fragment key={k}>
                    {v.left && (
                      <Grid item xs={6} className={classes.gridItem}>
                        <img
                          src={withPrefix(v.left.image)}
                          width={v.left.width}
                        />
                      </Grid>
                    )}
                    {v.center && (
                      <Grid
                        item
                        xs={12}
                        className={classNames(classes.center, classes.gridItem)}
                      >
                        <img
                          src={withPrefix(v.center.image)}
                          width={v.center.width}
                        />
                      </Grid>
                    )}
                    {v.right && (
                      <Grid
                        item
                        xs={6}
                        className={classNames(classes.right, classes.gridItem)}
                      >
                        <img
                          className={
                            v.right.image.indexOf("Philips") > 0
                              ? classes.opacity
                              : null
                          }
                          src={withPrefix(v.right.image)}
                          width={v.right.width}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
            </Grid>
          </Hidden>
        </Grid>
        <div className={classes.space} />
      </Grid>
    </React.Fragment>
    </Layout>
  );
};

export default withStyles(styles)(ResultsPageTemplate);

export const aboutPageQuery = graphql`
  query ResultsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        mobile {
          mobileImages {
            left {
              image
              width
            }
            center {
              image
              width
            }
            right {
              image
              width
            }
          }
        }
        desktop {
          desktopImages {
            left {
              image
              width
            }
            center {
              image
              width
            }
            right {
              image
              width
            }
          }
        }
        testimonials {
          author
          quote
        }
      }
    }
  }
`;
